<template>
  <the-layout-view-content class="content">
    <base-h1 :short="true">Cześć,<br />{{ userProfile.nameFirst }}!</base-h1>
    <text-description>
      Dołącz do nas, <br />
      aby kontaktować z nianiami.
    </text-description>

    <div class="wrapper-buttons">
      <ButtonCta
        :to="{ name: 'OfferNannyCreate' }"
        :label="'Dodaj ogłoszenie'"
      />
      <ButtonLink
        :to="{ name: 'ListNanny' }"
        :label="'Przeglądaj profile niań'"
      />
    </div>
  </the-layout-view-content>
</template>

<script>
import { useUserProfileStore } from "@/stores/userProfile";

import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

export default {
  components: { TextDescription, ButtonCta, ButtonLink },

  setup() {
    const userProfile = useUserProfileStore();

    return {
      userProfile,
    };
  },
};
</script>

<style scoped>
.content {
  row-gap: 80px;
}
.wrapper-buttons {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}
</style>
