<template>
  <BaseText class="description">
    <slot>{{ text }}</slot>
  </BaseText>
</template>

<script>
import BaseText from "@/components/UI/Base/BaseText.vue";

export default {
  components: { BaseText },

  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.description {
  width: var(--text-description--width);
  text-align: center;
}
</style>
